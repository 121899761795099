import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { defaultLanguage } from "@config/i18n";
import Custom404 from "@modules/Custom404";
export const getStaticProps = async ({
  locale
}: {
  locale: string;
}) => ({
  props: { ...(await serverSideTranslations(locale || defaultLanguage, ["components", "pages"]))
  }
});
export default Custom404;